@import "shared/style/reset.scss";
@import "shared/style/variables.scss";
@import "shared/style/helpers.scss";

body {
  font-family: $fontFamily;
  color: $fontColor;
}

a {
  font-size: 20px;
  color: $fontColor;
  text-decoration: underline;
  font-weight: normal;
}

h2 {
  font-size: 27px;
}

h3 {
  font-size: 25px;
}

h4 {
  font-size: 22px;
}

h5 {
  font-size: 18px;
}

p,
span {
  font-size: 20px;
}

.mat-spinner {
  margin: auto;
}

.mat-tab-label {
  box-shadow: 0 3px 6px $box-shadow-color !important;
  font-size: 18px;
  font-weight: bold;
  opacity: 1 !important;
}

.mat-ink-bar {
  background-color: $primary-color !important;
}

.mat-checkbox {
  .mat-checkbox-frame {
    border-radius: 5px;
    height: 18px;
    width: 18px;
    border-color: $primary-color;
    border-width: 1px;
  }

  .mat-checkbox-background {
    border-radius: 4px;
    height: 18px;
    width: 18px;
  }
}
