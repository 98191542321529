$primary-color: #33393B;
$primary-light-color: #33393B;
$secondary-color: #FFFFFF;
$secondary-light-color: #FFFFFF;

$box-shadow-color: #00000038;

$fontColor: #33393B;
$fontFamily: Raleway;
$fontMacro: Montserrat;
$fontMicro: Lato;
